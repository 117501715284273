export default {
  props: {
    listKey: String,
    config: Object,
  },
  methods: {
    onClick() {
      if(typeof this.config.callback != 'function') return
      this.config.callback(window.eagleLodash.cloneDeep(this.row))
    },
  },
  computed: {
    listApi() {
      return this.$store.getters[`list/${this.listKey}/api`]
    },
    label() {
      if(typeof this.config.label === 'function') {
        return this.config.label(this.row)
      }

      return this.config.label || null
    },
    route() {
      if(typeof this.config.route === 'function') {
        return this.config.route(this.row)
      }

      return null
    },
    href() {
      if(typeof this.config.href === 'function') {
        return this.config.href(this.row)
      }

      return null
    },
    icon() {
      if(typeof this.config.icon === 'function') {
        return this.config.icon(this.row)
      }

      if(this.config.icon) return this.config.icon

      return null
    },
    color() {
      if(typeof this.config.color === 'function') {
        return this.config.color(this.row)
      }

      if(this.config.color) return this.config.color

      return 'primary'
    },
    linkTarget() {
      if(this.config.linkTarget === undefined) return '_blank'
      return this.config.linkTarget || '_blank'
    },
    buttonProperties() {
      if(typeof this.config.buttonProperties === 'function') {
        return this.config.buttonProperties(this.row)
      }

      return null
    },
  },
}
